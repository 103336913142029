@media (min-width: 1199.98px) {
  .MobileSeguimientoPage-background {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .MobileSeguimientoPage-background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSeguimientoPage-content {
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 12vh auto;
  }

  .MobileSeguimientoPage-C-B1 {
    background-color: none;
  }

  .MobileSeguimientoPage-C-B2 {
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSeguimientoPage-C-B2B1 {
    border-radius: 0.5vh;
    border: 0.1vh solid rgb(128, 128, 128, 0.5);
    height: 95%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*MobileSearcher*/

  .MobileSearcher-background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSearcher-content {
    height: 40%;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    align-content: space-around;
    justify-items: center;
  }

  /*MobileSearcher-INPUT*/

  .MobileSearcher-INP-DIV {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSearcher-inp {
    height: 3.5vh;
    width: 100%;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
    background-color: transparent;
    border-radius: 0.5vh;
    border: 0.1vh solid rgb(128, 128, 128, 0.5);
    padding-left: 1.5vh;
    padding-top: 2vh;
  }

  .MobileSearcher-label {
    left: 0;
    position: absolute;
    transition: 0.2s ease-in-out;
    transform-origin: 1vh 0;
    padding-left: 1.5vh;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(124, 124, 124);
    pointer-events: none;
  }

  .MobileSearcher-inp:focus + .MobileSearcher-label,
  .MobileSearcher-inp:not(:placeholder-shown) + .MobileSearcher-label {
    transform: translateY(-1vh) scale(0.8);
  }

  .MobileSearcher-btn {
    margin: 0;
    padding: 2vh;
    border-radius: 0.5vh;
    width: fit-content;
    height: fit-content;
    background-color: black;
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
  }

  /*Loader*/

  .MobileLoader-Loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(128, 128, 128, 1);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /*RenderOfMobileNotFoundOrder*/

  .RenderOfMobileNotFoundOrder-background {
    height: 80%;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    justify-items: center;
    align-content: space-around;
  }

  .RenderOfMobileNotFoundOrder-B1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-items: center;
    align-content: space-between;
    grid-row-gap: 2vh;
  }

  .RenderOfMobileNotFoundOrder-svg {
    height: 7vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .RenderOfMobileNotFoundOrder-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
    text-align: center;
  }

  .RenderOfMobileNotFoundOrder-B2 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 2vh;
    justify-content: center;
    align-content: space-between;
  }

  .RenderOfMobileNotFoundOrder-btn {
    margin: 0;
    padding: 2vh;
    border-radius: 0.5vh;
    width: fit-content;
    height: fit-content;
    background-color: black;
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
  }

  /*RenderOfMobilePendingOrApprovedOrder*/

  .RenderOfMobilePendingOrApprovedOrder-background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobilePendingOrApprovedOrder-content {
    height: 95%;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
    align-content: space-between;
    justify-items: center;
  }

  .RenderOfMobilePendingOrApprovedOrder-txt-1 {
    margin: 0;
    font-size: 4vw;
    font-weight: 400;
    text-align: left;
  }

  .RenderOfMobilePendingOrApprovedOrder-C-B1 {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    justify-content: flex-start;
    align-content: space-between;
    grid-row-gap: 1vh;
  }

  .RenderOfMobilePendingOrApprovedOrder-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    text-align: left;
  }

  .RenderOfMobilePendingOrApprovedOrder-C-B2 {
    height: auto;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-rows: auto;
    padding: 2vh;
    overflow-x: scroll;
    overflow-y: hidden;
    grid-column-gap: 5vh;
    align-items: center;
  }

  .RenderOfMobilePendingOrApprovedOrder-C-B2::-webkit-scrollbar {
    display: none;
  }

  .RenderOfMobilePendingOrApprovedOrder-C-B3 {
    height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobilePendingOrApprovedOrder-btn {
    margin: 0;
    padding: 2vh;
    border-radius: 0.5vh;
    width: fit-content;
    height: fit-content;
    background-color: black;
    color: white;
    font-size: 3.5vw;
    font-weight: 500;
  }

  /*RenderOfMobileProductsOrdered*/

  .RenderOfMobileProductsOrdered-background {
    height: auto;
    border: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-radius: 0.5vh;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh;
    text-decoration: none;
  }

  .RenderOfMobileProductsOrdered-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    align-content: space-around;
  }

  .RenderOfMobileProductsOrdered-C-B1 {
    height: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .RenderOfMobileProductsOrdered-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(64, 64, 64);
  }

  .RenderOfMobileProductsOrdered-svg {
    height: 2vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .RenderOfMobileProductsOrdered-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(64, 64, 64);
  }

  /*RenderOfMobileRoadMap*/

  .RenderOfMobileRoadMap-background {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    justify-content: flex-start;
    align-content: space-around;
  }

  .RenderOfMobileRoadMap-B1 {
    height: fit-content;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    grid-column-gap: 2vh;
  }

  .RenderOfMobileRoadMap-circle {
    height: 3vh;
    width: 3vh;
    background-color: rgb(124, 124, 124);
    border-radius: 50%;
  }

  .RenderOfMobileRoadMap-circle-black {
    background-color: rgb(44, 44, 44);
  }

  .RenderOfMobileRoadMap-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(64, 64, 64);
  }

  .RenderOfMobileRoadMap-txt-1-black {
    color: rgb(54, 54, 54);
    font-weight: 600;
  }
}
