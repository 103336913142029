@media (min-width: 1199.98px) {
  .MobileMercadoPago-background {
    display: none;
  }

  .MobileMercadoPago-background-notdisplayed {
    display: none;
  }

  .MobileMercadoPago-B1 {
    display: none;
  }

  .MobileMercadoPago-svg {
    display: none;
  }

  .MobileMercadoPago-txt-1 {
    display: none;
  }

  .MobileMercadoPago-txt-2 {
    display: none;
  }

  .MobileMercadoPago-B2 {
    display: none;
  }

  .MobileMercadoPago-txt-3 {
    display: none;
  }

  .MobileMercadoPago-B2B1 {
    display: none;
  }

  .MobileMercadoPago-B2B1::-webkit-scrollbar {
    display: none;
  }

  .MobileMercadoPago-B3 {
    display: none;
  }

  .MobileMercadoPago-txt-4 {
    display: none;
  }

  .MobileMercadoPago-btn {
    display: none;
  }

  .MobileMercadoPago-B5 {
    display: none;
  }

  /*RenderOfMobileProductsOredered*/

  .RenderOfMobileProductsOredered-background {
    display: none;
  }

  .RenderOfMobileProductsOredered-B1 {
    display: none;
  }

  .RenderOfMobileProductsOredered-img {
    display: none;
  }

  .RenderOfMobileProductsOredered-count {
    display: none;
  }

  .RenderOfMobileProductsOredered-B2 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-1 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-2 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-3 {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .MobileMercadoPago-background {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto 40vh auto;
    align-content: space-around;
    justify-items: center;
    grid-row-gap: 6vh;
  }

  .MobileMercadoPago-background-notdisplayed {
    display: none;
  }

  .MobileMercadoPago-B1 {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: space-between;
    justify-items: center;
    grid-row-gap: 2vh;
  }

  .MobileMercadoPago-svg {
    height: 10vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .MobileMercadoPago-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: center;
  }

  .MobileMercadoPago-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }

  .MobileMercadoPago-B2 {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content auto;
    align-content: space-between;
    background-color: rgb(240, 240, 240);
    border-top: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .MobileMercadoPago-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(64, 64, 64);
  }

  .MobileMercadoPago-B2B1 {
    height: 30vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 12vh;
    overflow-x: hidden;
    overflow-y: scroll;
    grid-row-gap: 1vh;
    padding: 2vh 0 2vh 0;
  }

  .MobileMercadoPago-B2B1::-webkit-scrollbar {
    display: none;
  }

  .MobileMercadoPago-B3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 2vh 0;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .MobileMercadoPago-txt-4 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: center;
  }

  .MobileMercadoPago-btn {
    margin: 0 0 4vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh 3vh 2vh 3vh;
    width: fit-content;
    border: none;
    background-color: rgb(54, 54, 54);
    border-radius: 0.5vh;
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
    text-decoration: none;
  }

  .MobileMercadoPago-B5 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 2vh 0;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }
}
