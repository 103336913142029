.MobileMenuSearch-bacground {
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 8vh auto;
  justify-content: center;
}

.MobileMenuSearch-content {
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 80% auto;
  grid-template-rows: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0vh 3vh 0vh 3vh;
}

.MobileMenuSearch-C-B1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.MobileMenuSearch-input {
  height: 50%;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 3vw;
  font-weight: 400;
  padding: 0;
  color: black;
}

.MobileMenuSearch-input::placeholder {
  font-style: italic;
}

.MobileMenuSearch-C-B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileMenuSearch-svg-1 {
  height: 3vh;
  width: auto;
  fill: rgb(94, 94, 94);
}

.MobileMenuSearch-content-2 {
  grid-row: 2/3;
  background-color: whitesmoke;
  height: fit-content;
  width: 100%;
  padding: 3vh;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 4vh;
}

.MobileMenuSearch-content-2-notdisplayed {
  display: none;
}

.MobileMenuSearch_DB_Products-background {
  height: 10vh;
  width: fit-content;
  display: grid;
  grid-template-columns: 10vh max-content;
  grid-column-gap: 4vh;
  text-decoration: none;
}

.MobileMenuSearch_DB_Products-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileMenuSearch_DB_Products-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.MobileMenuSearch_DB_Products-B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-content: flex-start;
  grid-row-gap: 0.5vh;
}

.MobileMenuSearch_DB_Products-txt-1 {
  margin: 0;
  width: max-content;
  font-size: 3.5vw;
  font-weight: 600;
  color: rgb(94, 94, 94);
}

.MobileMenuSearch_DB_Products-txt-2 {
  margin: 0;
  width: max-content;
  font-size: 3.5vw;
  font-weight: 400;
  color: rgb(94, 94, 94);
}
