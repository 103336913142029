.EP-background {
  background-color: whitesmoke;
  height: 120%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EP-content {
  height: 100%;
  width: 60%;
  display: grid;
  grid-template-columns: 100;
  grid-template-rows: 5% 95%;
}

.EP-C-B1 {
  background-color: none;
}

.EP-C-B2 {
  background-color: none;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 12% 35% 20% 13% 20%;
}

.EP-C-B2B1 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30% 30%;
  align-content: space-around;
  justify-content: center;
}

.EP-C-B2B1B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-txt-1 {
  margin: 0;
  font-size: 1vw;
  font-weight: 500;
  color: rgb(60, 60, 60);
}

.EquipoNombredbLoader-background {
  height: 100%;
  width: 30%;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

.EP-C-B2B1B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-txt-2 {
  margin: 0;
  font-size: 0.6vw;
  font-weight: 400;
  letter-spacing: 0.03vw;
  color: rgb(60, 60, 60);
}

.EquipoNombre2dbLoader-background {
  height: 100%;
  width: 50%;
  animation: skeleton-loading 1s linear infinite alternate;
}

.EP-C-B2B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 70%;
  align-content: space-around;
  justify-content: center;
}

.EP-C-B2B2B1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.EP-txt-3 {
  margin: 0;
  font-size: 0.6vw;
  font-weight: 500;
  color: black;
}

.EP-C-B2B2B2 {
  display: grid;
  grid-template-columns: repeat(4, 22%);
  grid-template-rows: 100%;
  justify-content: space-between;
}

.EquiposdbProducts-background {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 75% 20%;
  align-content: space-between;
  animation: 0.7s ease-in EquiposdbProducts-animation;
  text-decoration: none;
}

@keyframes EquiposdbProducts-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.EquiposdbProducts-background-notdisplayed {
  display: none;
}

.EquiposdbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.EquiposdbProductsLoader-background-notdisplayed {
  display: none;
}

.EquiposdbProducts-background-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EquiposdbProducts-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: 0.2s;
}

.EquiposdbProducts-background:hover .EquiposdbProducts-img {
  opacity: 0.7;
}

.EquiposdbProducts-background-B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-content: space-around;
}

.EquiposdbProducts-txt-1 {
  margin: 0;
  text-align: center;
  margin: 0;
  font-size: 0.65vw;
  font-weight: 600;
  color: rgb(94, 94, 94);
}

.EquiposdbProducts-txt-2 {
  margin: 0;
  text-align: center;
  font-size: 0.65vw;
  font-weight: 400;
  color: rgb(94, 94, 94);
}

.EP-C-B2B3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-C-B2B3B1 {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-txt-4 {
  margin: 0;
  font-size: 0.6vw;
  font-weight: 400;
  color: rgb(60, 60, 60);
  line-height: 0.9vw;
  letter-spacing: 0.05vw;
}

.EquipoHistoriadbLoader-background {
  height: 100%;
  width: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
}

.EP-C-B2B4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-C-B2B4B1 {
  height: 65%;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(5, 18%);
  grid-template-rows: 100%;
  justify-content: space-around;
}

.EquiposImagenesdb-background {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.EquiposImagenesdb-background:hover {
  animation: EquiposImagenesdb-animation 0.6s ease-in-out infinite alternate;
}

@keyframes EquiposImagenesdb-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-7%);
  }
}

.EquiposImagenesdb-background-notdisplayed {
  display: none;
}

.EquiposImagenesdb-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.EquiposImagenesdbLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.EquiposImagenesdbLoader-background-notdisplayed {
  display: none;
}

.EP-C-B2B5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EP-C-B2B5B1 {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .EP-background {
    display: none;
  }
}
