.PG-background {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PG-content {
  height: 100%;
  width: 60%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6% 10% 84%;
}

/*BOX-1*/

.PG-C-B1 {
  background-color: none;
}

/*BOX-2*/

.PG-C-B2 {
  display: flex;
  align-items: center;
}

.PG-C-B2B1 {
  height: 50%;
  width: fit-content;
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-template-rows: 100%;
  grid-column-gap: 1vh;
}

.PG-C-B2B1B1 {
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
}

.PG-txt-1 {
  margin: 0;
  color: rgb(94, 94, 94);
  font-size: 0.6vw;
  font-weight: 400;
  width: max-content;
}

.PG-txt-1-v2 {
  margin: 0;
  color: rgb(94, 94, 94);
  font-size: 0.6vw;
  font-weight: 400;
  width: max-content;
}

.PG-txt-1-v2:hover {
  text-decoration: underline;
}

.PG-C-B2B1B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PG-svg-1 {
  height: 25%;
  width: fit-content;
  fill: gray;
}

.PG-C-B2B1B3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*BOX-3*/

.PG-C-B3 {
  display: grid;
  grid-template-columns: 60% 35%;
  grid-template-rows: 100%;
  justify-content: space-between;
}

.PG-C-B3B1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.PG-C-B3B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, 1fr);
}

/*BOX-3-1*/

.PG-C-B3B2B1 {
  border-bottom: 1px solid rgb(128, 128, 128, 0.5);
  display: grid;
  grid-template-rows: min-content auto;
}

.PG-C-B3B2B1B1 {
  display: grid;
  grid-row-gap: 0.5vh;
}

.PG-C-B3B2B1B1B1 {
  background-color: none;
}

.PG-txt-2 {
  margin: 0;
  font-size: 2vw;
  line-height: 4vh;
  font-weight: 600;
  color: rgb(94, 94, 94);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PG-C-B3B2B1B1B2 {
  background-color: none;
}

.PG-txt-3 {
  margin: 0;
  font-size: 0.8vw;
  font-weight: 500;
  color: rgb(94, 94, 94);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PG-C-B3B2B1B1B3 {
  background-color: none;
}

.PG-txt-4 {
  margin: 0;
  font-size: 0.8vw;
  font-weight: 500;
  color: rgb(14, 14, 14);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PG-C-B3B2B1B2 {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.PG-C-B3B2B1B2B1 {
  background-color: none;
  width: 100%;
  height: 5vh;
  display: grid;
  grid-template-rows: auto auto;
}

.PG-C-B3B2B1B2B1B1 {
  height: 2.5vh;
  display: flex;
  align-items: center;
}

.PG-txt-5 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(14, 14, 14);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PG-C-B3B2B1B2B1B2 {
  height: 2.5vh;
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PG-select {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.PG-txt-6 {
  margin: 0;
  font-size: 0.8vw;
  font-weight: 700;
  text-indent: 0;
}

.PG-C-B3B2B1B2B1B2B1 {
  background-color: none;
  display: flex;
  align-items: center;
}

.PG-txt-6 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(14, 14, 14);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PG-C-B3B2B1B2B1B2B2 {
  background-color: none;
  display: flex;
  align-items: center;
}

/*BOX-3-2*/

.PG-C-B3B2B2 {
  border-bottom: 1px solid rgb(128, 128, 128, 0.5);
  display: grid;
  grid-template-rows: 20% 20% 30% 30%;
}

.PG-C-B3B2B2B1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.PG-C-B3B2B2B2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.PG-C-B3B2B2B2B1 {
  height: 50%;
  width: 30%;
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: 100%;
  cursor: pointer;
}

.PG-C-B3B2B2B2B1:hover {
  opacity: 0.6;
}

.PG-C-B3B2B2B2B1B1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.PG-svg-3 {
  height: 70%;
  width: fit-content;
}

.PG-C-B3B2B2B2B1B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PG-txt-10 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 400;
  color: black;
  text-decoration: underline;
  letter-spacing: 0.03vw;
}

.PG-C-B3B2B2B3 {
  display: flex;
  align-items: center;
}

.PG-C-B3B2B2B3B1 {
  border: 1px solid rgb(0, 0, 0, 0.5);
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.PG-C-B3B2B2B3B1:hover {
  background-color: rgb(230, 230, 230);
}

.PG-txt-7 {
  font-size: 0.7vw;
  font-weight: 400;
  letter-spacing: 0.05vw;
  color: black;
}

.PG-C-B3B2B2B3B1-v2 {
  border: 1px solid rgb(128, 128, 128, 0.5);
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  transition: 0.2s;
}

.PG-C-B3B2B2B3B1-v2:hover {
  background-color: rgb(230, 230, 230);
}

.PG-txt-7-v2 {
  font-size: 0.7vw;
  font-weight: 400;
  letter-spacing: 0.05vw;
  color: rgb(128, 128, 128, 1);
}

.PG-C-B3B2B2B4 {
  display: flex;
  align-items: center;
}

.PG-C-B3B2B2B4B1 {
  border: 1px solid black;
  background-color: black;
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
}

.PG-C-B3B2B2B4B1:hover {
  background-color: rgb(33, 33, 33);
  border: 1px solid rgb(33, 33, 33);
}

.PG-txt-8 {
  font-size: 0.7vw;
  font-weight: 400;
  letter-spacing: 0.05vw;
  color: white;
  text-decoration: none;
}

/*BOX-3-3*/

.PG-C-B3B2B3 {
  background-color: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.PG-C-B3B2B3B1 {
  background-color: none;
  height: 20%;
  width: 100%;
  display: grid;
  grid-template-rows: 100% auto;
}

.PG-C-B3B2B3B1B1 {
  grid-row: 1/2;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  cursor: pointer;
}

.PG-C-B3B2B3B1B1B1 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.PG-txt-9 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 400;
  letter-spacing: 0.05vw;
}

.PG-C-B3B2B3B1B1B2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.PG-svg-2-open {
  height: 30%;
  width: fit-content;
  transition: 0.5s;
}

.PG-svg-2-closed {
  height: 30%;
  width: fit-content;
  transform: rotate(-90deg);
  transition: 0.5s;
}

/*RenderOfSizeError*/

.ROSE-background {
  background-color: rgb(255, 182, 182);
  border-radius: 0.3vh;
  padding: 0 1vh 0 1vh;
  font-size: 0.8vw;
  font-weight: 400;
  color: rgb(78, 0, 0);
  height: fit-content;
  width: fit-content;
  animation: 0.5s ease RenderOfSizeErrorAnimation;
}

@keyframes RenderOfSizeErrorAnimation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

/*Loaders*/

.PGdbNameLoader1-background {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 50%;
  width: 20vh;
}

.PGdbNameLoader2-background {
  height: 5vh;
  width: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
}

.PGdbCategoriaLoader-background {
  height: 2vh;
  width: 10vh;
  animation: skeleton-loading 1s linear infinite alternate;
}

.PGdbPrecioLoader-background {
  height: 2vh;
  width: 15vh;
  animation: skeleton-loading 1s linear infinite alternate;
}

@media (max-width: 1199.98px) {
  .PG-background {
    display: none;
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .PG-background {
    display: none;
  }
}