html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Heebo", sans-serif;
  background-color: whitesmoke;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c4c4c4 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1.5vh;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
  border: 1px solid #ffffff;
}
