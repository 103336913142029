.S1-background {
  background-image: url(../../../../Images/image9.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.S1-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6% 88% 6%;
}

.S1-C-B1 {
  border: none;
}

.S1-C-B2 {
  border: none;
}

.S1-C-B3 {
  display: flex;
  justify-content: center;
}

.S1-C-B3B1 {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounceInF 2s infinite 0s;
  cursor: pointer;
}

@keyframes bounceInF {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(-30%);
  }
  60% {
    transform: translateY(-30%);
  }
}

.S1-svg {
  height: 70%;
  width: fit-content;
  fill: white;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .S1-background {
    display: none;
  }
}
