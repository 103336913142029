@media (max-width: 1199.98px) {
  .OrderSummary-background {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 8vh auto;
  }

  .OrderSummary-B1 {
    grid-row: 1/2;
    background-color: rgb(240, 240, 240);
    padding: 0 2vh 0 2vh;
    display: grid;
    grid-template-columns: min-content auto auto;
    align-items: center;
    border-top: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
    grid-column-gap: 2vh;
  }

  .OrderSummary-svg-1 {
    height: 3vh;
    width: auto;
    fill: black;
  }

  .OrderSummary-B1B1 {
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .OrderSummary-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
  }

  .OrderSummary-svg-2 {
    height: 2vh;
    width: auto;
    fill: black;
  }

  .OrderSummary-svg-2-rotated {
    transform: rotate(180deg);
  }

  .OrderSummary-txt-2 {
    margin: 0;
    font-size: 4vw;
    font-weight: 500;
    color: black;
    text-align: end;
  }

  .OrderSummary-B2-opened {
    height: 60vh;
    grid-row: 2/3;
    background-color: whitesmoke;
    transition: 0.3s;
    padding: 2vh 2vh 0 2vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 68% 18% 14%;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .OrderSummary-B2-closed {
    height: 0;
    opacity: 0;
    padding: 0vh 2vh 0 2vh;
    border: none;
  }

  .OrderSummary-B2B1 {
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 28%;
    overflow-x: hidden;
    overflow-y: scroll;
    grid-row-gap: 1vh;
  }

  .OrderSummary-B2B1::-webkit-scrollbar {
    display: none;
  }

  .OrderSummary-B2B2 {
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content;
    align-content: space-around;
  }

  .OrderSummary-B2B2B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .OrderSummary-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(74, 74, 74);
  }

  .OrderSummary-txt-4 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }

  .OrderSummary-B2B2B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .OrderSummary-B2B3 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .OrderSummary-txt-5 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(74, 74, 74);
  }

  .OrderSummary-B2B3B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .OrderSummary-txt-6 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(74, 74, 74);
  }

  .OrderSummary-txt-7 {
    margin: 0;
    font-size: 5vw;
    font-weight: 600;
    color: rgb(74, 74, 74);
  }

  /*MobileRenderOfCartSummary*/

  .MobileRenderOfCartSummary-background {
    padding: 1vh 0 1vh 0;
    display: grid;
    grid-template-columns: 9vh 50% auto;
    grid-template-rows: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .MobileRenderOfCartSummary-B1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-radius: 0.5vh;
    padding: 0.5vh;
    position: relative;
  }

  .MobileRenderOfCartSummary-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .MobileRenderOfCartSummary-count {
    height: 2.5vh;
    width: 2.5vh;
    background-color: rgb(124, 124, 124);
    position: absolute;
    top: -1vh;
    right: -1vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3vw;
    font-weight: 500;
  }

  .MobileRenderOfCartSummary-B2 {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: flex-start;
  }

  .MobileRenderOfCartSummary-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(44, 44, 44);
  }

  .MobileRenderOfCartSummary-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(110, 110, 110);
  }

  .MobileRenderOfCartSummary-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }

  /*RenderOfMobileCoveredShipping*/

  .RenderOfMobileCoveredShipping-background {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    justify-content: space-between;
  }

  .RenderOfMobileCoveredShipping-B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .RenderOfMobileCoveredShipping-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(74, 74, 74);
  }

  .RenderOfMobileCoveredShipping-svg {
    height: 2vh;
    width: auto;
  }

  .RenderOfMobileCoveredShipping-B2 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .RenderOfMobileCoveredShipping-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(0, 158, 0);
  }

  .RenderOfMobileCoveredShipping-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
    text-decoration: line-through;
  }

  /*RenderOfMobileNotCoveredShipping*/

  .RenderOfMobileNotCoveredShipping-background {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .RenderOfMobileNotCoveredShipping-B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .RenderOfMobileNotCoveredShipping-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(74, 74, 74);
  }

  .RenderOfMobileNotCoveredShipping-svg {
    height: 2vh;
    width: auto;
  }

  .RenderOfMobileNotCoveredShipping-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }
}
