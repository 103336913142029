.S4-background {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

.S4-content {
  height: 100%;
  width: 98%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6% 6% 70% 18%;
}

.S4-C-B1 {
  border: none;
}

.S4-C-B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S4-txt-1 {
  margin: 0;
  font-size: 1vw;
  font-weight: 700;
  color: rgb(94, 94, 94);
}

.S4-C-B3 {
  display: grid;
  grid-template-columns: repeat(4, 22%);
  grid-template-rows: 90%;
  justify-content: space-around;
  align-content: space-around;
}

.S4-C-B4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S4-btn {
  border: 0.1vh solid rgb(141, 141, 141);
  color: rgb(141, 141, 141);
  font-size: 0.6vw;
  font-weight: 500;
  padding: 1vh 2vh 1vh 2vh;
  text-decoration: none;
  transition: 0.2s;
}

.S4-btn:hover {
  background-color: rgb(250, 250, 250);
}

/*S4dbProducts*/

.S4dbProducts-background {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60% 10%;
  align-content: space-around;
  text-decoration: none;
}

.S4dbProducts-background:hover {
  animation: S4dbProducts-animation-2 0.6s ease-in-out infinite alternate;
}

@keyframes S4dbProducts-animation-2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1%);
  }
}

.S4dbProducts-background-notdisplayed {
  display: none;
}

.S4dbProducts-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S4dbProducts-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.S4dbProducts-B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-items: center;
}

.S4dbProducts-txt-1 {
  margin: 0;
  text-align: center;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(128, 128, 128);
}

.S4dbProducts-txt-2 {
  margin: 0;
  text-align: center;
  font-size: 0.7vw;
  font-weight: 400;
  color: gray;
}

/*S4dbProductsLoader*/

.S4dbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.S4dbProductsLoader-background-notdisplayed {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .S4-background {
    display: none;
  }
}
