.S2-background {
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.S2-content {
  height: 100%;
  width: 98%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 90%;
  align-content: space-around;
}

.S2-C-B1 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
}

.S2-txt-1 {
  margin: 0;
  font-size: 1vw;
  font-weight: 700;
  color: rgb(94, 94, 94);
  text-align: center;
}

.S2-txt-2 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 400;
  color: gray;
  text-align: center;
}

.S2-C-B2 {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: repeat(2, 45%);
  justify-content: space-around;
  align-content: space-around;
}

/*S2dbProducts-background*/

.S2dbProducts-background {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60% 10%;
  align-content: space-around;
  animation: 0.7s ease-in S2dbProducts-animation;
  text-decoration: none;
}

@keyframes S2dbProducts-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.S2dbProducts-background-notdisplayed {
  display: none;
}

.S2dbProducts-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S2dbProducts-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.S2dbProducts-B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-items: center;
}

.S2dbProducts-txt-1 {
  margin: 0;
  text-align: center;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(128, 128, 128);
}

.S2dbProducts-txt-2 {
  margin: 0;
  text-align: center;
  font-size: 0.7vw;
  font-weight: 400;
  color: gray;
}

/*S2dbProductsLoader*/

.S2dbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.S2dbProductsLoader-background-notdisplayed {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .S2-background {
    display: none;
  }
}
