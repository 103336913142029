.MP-background {
  height: 100%;
  width: 100%;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .MP-background {
    display: none;
  }
}
