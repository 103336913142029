/*Disable on desktop*/

.MobileSectionFour-background {
  display: none;
}

@media (max-width: 1199.98px) {
  .MobileSectionFour-background {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MobileSectionFour-content {
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6% 6% 73% 15%;
  }

  .MobileSectionFour-C-B1 {
    border: none;
  }

  .MobileSectionFour-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSectionFour-txt-1 {
    margin: 0;
    font-size: 5.5vw;
    font-weight: 700;
    color: rgb(94, 94, 94);
  }

  .MobileSectionFour-C-B3 {
    display: grid;
    grid-template-columns: repeat(2, 40%);
    grid-template-rows: repeat(2, 49%);
    justify-content: space-around;
    align-content: space-around;
  }

  .MobileSectionFour-C-B4 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSectionFour-btn {
    border: 0.1vh solid rgb(141, 141, 141);
    color: rgb(141, 141, 141);
    font-size: 3vw;
    font-weight: 500;
    padding: 1vh 2vh 1vh 2vh;
    text-decoration: none;
    transition: 0.2s;
  }

  .MobileSectionFour-btn:hover {
    background-color: rgb(250, 250, 250);
  }

  /*MobileSectionFour_DB_Products*/

  .MobileSectionFour_DB_Products-background {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 70% 24%;
    align-content: space-between;
    text-decoration: none;
  }

  .MobileSectionFour_DB_Products-background:hover {
    animation: MobileSectionFour_DB_Products-animation 0.6s ease-in-out infinite
      alternate;
  }

  @keyframes MobileSectionFour_DB_Products-animation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1%);
    }
  }

  .MobileSectionFour_DB_Products-background-notdisplayed {
    display: none;
  }

  .MobileSectionFour_DB_Products-B1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSectionFour_DB_Products-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .MobileSectionFour_DB_Products-B2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: space-between;
  }

  .MobileSectionFour_DB_Products-txt-1 {
    margin: 0;
    text-align: center;
    font-size: 2.8vw;
    font-weight: 500;
    color: rgb(128, 128, 128);
  }

  .MobileSectionFour_DB_Products-txt-2 {
    margin: 0;
    text-align: center;
    font-size: 2.8vw;
    font-weight: 400;
    color: gray;
  }

  /*S4dbProductsLoader*/

  .MobileSectionFour-Loader-background {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .MobileSectionFour-Loader-background-notdisplayed {
    display: none;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 0%, 75%);
    }

    100% {
      background-color: hsl(0, 0%, 95%);
    }
  }
}
