@media (min-width: 1199.98px) {
  .MobileTransferenciaBancaria-background {
    display: none;
  }

  .MobileTransferenciaBancaria-B1 {
    display: none;
  }

  .MobileTransferenciaBancaria-svg {
    height: 10vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .MobileTransferenciaBancaria-txt-1 {
    display: none;
  }

  .MobileTransferenciaBancaria-txt-2 {
    display: none;
  }

  .MobileTransferenciaBancaria-B2 {
    display: none;
  }

  .MobileTransferenciaBancaria-txt-3 {
    display: none;
  }

  .MobileTransferenciaBancaria-B2B1 {
    display: none;
  }

  .MobileTransferenciaBancaria-B2B1::-webkit-scrollbar {
    display: none;
  }

  .MobileTransferenciaBancaria-B3 {
    display: none;
  }

  .MobileTransferenciaBancaria-txt-4 {
    display: none;
  }

  .MobileTransferenciaBancaria-btn {
    display: none;
  }

  /*RenderOfMobileProductsOredered*/

  .RenderOfMobileProductsOredered-background {
    display: none;
  }

  .RenderOfMobileProductsOredered-B1 {
    display: none;
  }

  .RenderOfMobileProductsOredered-img {
    display: none;
  }

  .RenderOfMobileProductsOredered-count {
    display: none;
  }

  .RenderOfMobileProductsOredered-B2 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-1 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-2 {
    display: none;
  }

  .RenderOfMobileProductsOredered-txt-3 {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .MobileTransferenciaBancaria-background {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 40vh auto;
    align-content: space-around;
    justify-items: center;
    grid-row-gap: 6vh;
  }

  .MobileTransferenciaBancaria-B1 {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: space-between;
    justify-items: center;
    grid-row-gap: 2vh;
  }

  .MobileTransferenciaBancaria-svg {
    height: 10vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .MobileTransferenciaBancaria-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: center;
  }

  .MobileTransferenciaBancaria-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }

  .MobileTransferenciaBancaria-B2 {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content auto;
    align-content: space-between;
    background-color: rgb(240, 240, 240);
    border-top: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .MobileTransferenciaBancaria-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(64, 64, 64);
  }

  .MobileTransferenciaBancaria-B2B1 {
    height: 30vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 12vh;
    overflow-x: hidden;
    overflow-y: scroll;
    grid-row-gap: 1vh;
    padding: 2vh 0 2vh 0;
  }

  .MobileTransferenciaBancaria-B2B2 {
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-column-gap: 1vh;
  }

  .MobileTransferenciaBancaria-B2B1::-webkit-scrollbar {
    display: none;
  }

  .MobileTransferenciaBancaria-B3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 2vh 0;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .MobileTransferenciaBancaria-txt-4 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: center;
  }

  .MobileTransferenciaBancaria-btn {
    margin: 0 0 4vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh 1vh 2vh 1vh;
    width: fit-content;
    border: none;
    background-color: rgb(54, 54, 54);
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
    text-decoration: none;
    text-align: center;
  }

  .MobileTransferenciaBancaria-btn-2 {
    margin: 0 0 4vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh 1vh 2vh 1vh;
    width: fit-content;
    border: none;
    background-color: black;
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
    text-decoration: none;
    text-align: center;
  }

  /*RenderOfMobileProductsOredered*/

  .RenderOfMobileProductsOredered-background {
    padding: 1vh 0 1vh 0;
    display: grid;
    grid-template-columns: 9vh 50% auto;
    grid-template-rows: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .RenderOfMobileProductsOredered-B1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0.1vh solid rgb(128, 128, 128, 0.5);
    border-radius: 0.5vh;
    padding: 0.5vh;
    position: relative;
  }

  .RenderOfMobileProductsOredered-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .RenderOfMobileProductsOredered-count {
    height: 2.5vh;
    width: 2.5vh;
    background-color: rgb(124, 124, 124);
    position: absolute;
    top: -1vh;
    right: -1vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3vw;
    font-weight: 500;
  }

  .RenderOfMobileProductsOredered-B2 {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: flex-start;
  }

  .RenderOfMobileProductsOredered-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(44, 44, 44);
  }

  .RenderOfMobileProductsOredered-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(110, 110, 110);
  }

  .RenderOfMobileProductsOredered-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }

  /*RenderOfMobileComprobanteSender*/

  .RenderOfMobileComprobanteSender-background {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 0.3s ease RenderOfMobileComprobanteSender-animation;
  }

  @keyframes RenderOfMobileComprobanteSender-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .RenderOfMobileComprobanteSender-content {
    height: 50%;
    width: 90%;
    background-color: whitesmoke;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.5vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 6vh auto;
    grid-row-gap: 2vh;
    align-content: space-between;
    justify-content: center;
    padding: 0 0 2vh 0;
  }

  .RenderOfMobileComprobanteSender-C-B1 {
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
    padding: 0 2vh 0 2vh;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    align-items: center;
  }

  .RenderOfMobileComprobanteSender-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
  }

  .RenderOfMobileComprobanteSender-svg-1 {
    height: 3.5vh;
    width: auto;
    fill: rgb(44, 44, 44);
  }

  .RenderOfMobileComprobanteSender-C-B2 {
    padding: 0 2vh 0 2vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-row-gap: 1vh;
    align-content: flex-start;
  }

  .RenderOfMobileComprobanteSender-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
    text-align: justify;
  }

  .RenderOfMobileComprobanteSender-C-B2B1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    width: fit-content;
    grid-row-gap: 1vh;
    padding: 1vh 0 1vh 0;
  }

  .RenderOfMobileComprobanteSender-C-B2B1B1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: flex-start;
    grid-column-gap: 2vh;
    align-items: center;
    width: fit-content;
    text-decoration: none;
  }

  .RenderOfMobileComprobanteSender-svg-2 {
    height: 2vh;
    width: auto;
    fill: black;
  }

  .RenderOfMobileComprobanteSender-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
  }

  .RenderOfMobileComprobanteSender-txt-4 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
    text-align: justify;
  }

  .RenderOfMobileComprobanteSender-txt-5 {
    font-style: italic;
  }

  .RenderOfMobileComprobanteSender-C-B3 {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobileComprobanteSender-btn {
    margin: 0;
    padding: 1.5vh;
    width: fit-content;
    height: fit-content;
    background-color: black;
    color: white;
    border-radius: 0.3vh;
  }
}
