@media (max-width: 1199.98px) {
  .NoScroll {
    /* when modal active */
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
    scroll-behavior: none;
  }

  .SectionTwo-background {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SectionTwo-content {
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 25% 25% 40%;
  }

  .SectionTwo-C-B1 {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    grid-template-rows: 100%;
    align-items: center;
    grid-column-gap: 1vh;
  }

  .SectionTwo-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    width: max-content;
  }

  .SectionTwo-txt-1:hover {
    text-decoration: underline;
  }

  .SectionTwo-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    width: max-content;
  }

  .SectionTwo-C-B2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 45%;
    align-content: space-between;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .SectionTwo-C-B2B1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content auto;
  }

  .SectionTwo-txt-3 {
    margin: 0;
    font-size: 5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
  }

  .SectionTwo-txt-4 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(112, 112, 112);
  }

  .SectionTwo-C-B2B2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: space-around;
  }

  .SectionTwo-txt-5 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(124, 124, 124);
  }

  .SectionTwo-select {
    height: 4vh;
    width: 100%;
    outline: none;
    background-color: white;
    border: none;
    padding: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
  }

  .SectionTwo-option {
    padding: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
  }

  .SectionTwo-C-B3 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 3.5vh auto 6vh 6vh;
    grid-row-gap: 1vh;
    align-content: center;
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.5);
  }

  .SectionTwo-C-B3B1 {
    border: 1px solid none;
    width: fit-content;
  }

  .SectionTwo-Error {
    margin: 0;
    width: fit-content;
    height: fit-content;
    background-color: rgb(255, 182, 182);
    color: rgb(78, 0, 0);
    font-size: 3.5vw;
    font-weight: 400;
    border-radius: 0.3vh;
    padding: 0 1vh 0 1vh;
    animation: 0.5s ease SectionTwo-Error-animation;
  }

  @keyframes SectionTwo-Error-animation {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .SectionTwo-Error-notdisplayed {
    display: none;
  }

  .SectionTwo-C-B3B2 {
    width: fit-content;
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: 100%;
    grid-column-gap: 1vh;
  }

  .SectionTwo-svg-1 {
    fill: black;
    height: 3vh;
    width: auto;
  }

  .SectionTwo-C-B3B1:hover {
    opacity: 0.7;
  }

  .SectionTwo-txt-6 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: black;
    text-decoration: underline;
  }

  .SectionTwo-btn1 {
    margin: 0;
    background-color: rgb(94, 94, 94);
    border: none;
    color: white;
    font-size: 3vw;
    font-weight: 400;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SectionTwo-btn1:hover {
    background-color: rgb(112, 112, 112);
  }

  .SectionTwo-btn1-SinStock {
    margin: 0;
    background-color: rgb(164, 164, 164);
    border: none;
    color: whitesmoke;
    font-size: 3vw;
    font-weight: 400;
    transition: 0.2s;
    cursor: not-allowed;
  }

  .SectionTwo-btn1-SinStock:hover {
    background-color: rgb(174, 174, 174);
  }

  .SectionTwo-btn2 {
    margin: 0;
    background-color: black;
    border: none;
    color: white;
    font-size: 3vw;
    font-weight: 400;
    transition: 0.2s;
  }

  .SectionTwo-btn2:hover {
    background-color: rgb(33, 33, 33);
  }

  .SectionTwo-C-B4 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 8vh 20vh auto;
  }

  .SectionTwo-C-B4B1 {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-content: center;
  }

  .SectionTwo-txt-7 {
    margin: 0;
    color: rgb(94, 94, 94);
    font-size: 3.5vw;
    font-weight: 400;
  }

  .SectionTwo-svg-2 {
    height: 3vh;
    width: auto;
    fill: rgb(94, 94, 94);
    transition: 0.5s;
  }

  .SectionTwo-svg-2-rotated {
    transform: rotate(90deg);
    transition: 0.5s;
  }

  .SectionTwo-txt-8 {
    text-align: left;
    margin: 0;
    color: rgb(94, 94, 94);
    font-size: 3.5vw;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .SectionTwo-span {
    background-color: white;
    border-top: 0.1vh solid rgb(128, 128, 128, 0.5);
    transition: 0.5s;
  }

  .SectionTwo-span-animtion {
    margin-top: -20vh;
    transition: 0.5s;
  }
}
