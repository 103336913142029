.PG-S2-background {
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PG-S2-content {
  background-color: none;
  height: 100%;
  width: 60%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 90%;
}

.PG-S2-C-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PG-S2-txt-1 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  letter-spacing: 0.05vw;
  color: rgb(94, 94, 94);
}

.PG-S2-C-B2 {
  background-color: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100%;
  grid-column-gap: 5vh;
  justify-content: space-between;
  align-items: flex-start;
}

.RA-background {
  height: 80%;
  width: 100%;
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  animation: 0.7s ease-in RA-background-animation;
}

@keyframes RA-background-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.RA-background-notdisplayed {
  display: none;
}

.RA-content {
  background-color: none;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 86% 7% 7%;
}

.RA-C-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RA-img {
  width: 90%;
  height: fit-content;
  transition: 0.2s;
}

.RA-background:hover .RA-img {
  opacity: 0.75;
}

.RA-C-B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RA-txt-1 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 400;
  color: rgb(94, 94, 94);
  text-align: center;
}

.RA-C-B3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RA-txt-2 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(94, 94, 94);
}

/*Loaders*/

.PGSTdbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 80%;
  width: 100%;
}

.PGSTdbProductsLoader-background-notdisplayed {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

@media (max-width: 1199.98px) {
  .PG-S2-background {
    display: none;
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .PG-S2-background {
    display: none;
  }
}