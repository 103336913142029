/*Disable on desktop*/

.NavbarMobileCart-background {
  display: none;
}

@media (max-width: 1199.98px) {
  .NavbarMobileCart-background {
    background-color: whitesmoke;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    animation: 0.2s ease NavbarMobileCart-animation;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  @keyframes NavbarMobileCart-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .NavbarMobileCart-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 8vh auto;
  }

  .NavbarMobileCart-C-B1 {
    border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0vh 3vh 0vh 3vh;
  }

  .NavbarMobileCart-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(94, 94, 94);
  }

  .NavbarMobileCart-svg-1 {
    height: 3vh;
    width: auto;
    fill: rgb(94, 94, 94);
  }

  .NavbarMobileCart-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*MobileEmptyCart*/

  .MobileEmptyCart-background {
    background: linear-gradient(
      0deg,
      rgb(245, 245, 245) 50%,
      rgb(235, 235, 235) 50%
    );
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileEmptyCart-content {
    background-color: none;
    height: 100%;
    width: auto;
    display: grid;
    grid-template-rows: 25% 50% 25%;
    grid-template-columns: 100%;
  }

  .MobileEmptyCart-C-B1 {
    background-color: none;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .MobileEmptyCart-txt-1 {
    margin: 0;
    font-size: 3vw;
    font-weight: 400;
    letter-spacing: 0.01vw;
    color: rgb(110, 110, 110);
  }

  .MobileEmptyCart-C-B2 {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileEmptyCart-C-B2B1 {
    height: 80%;
    width: 100%;
    display: grid;
    grid-template-rows: 10% 80% 10%;
    grid-template-columns: 100%;
    cursor: pointer;
  }

  .MobileEmptyCart-C-B2B1B1 {
    background-color: none;
    height: 100%;
    width: 100%;
  }

  .MobileEmptyCart-C-B2B1B2 {
    background-color: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileEmptyCart-C-B2B1B2B1 {
    background-color: whitesmoke;
    height: 22vh;
    width: 22vh;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileEmptyCart-svg-1 {
    height: 23%;
    width: 23%;
    fill: rgb(110, 110, 110, 0.5);
  }

  .MobileEmptyCart-C-B2B1B3 {
    background-color: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileEmptyCart-txt-2 {
    margin: 0;
    font-size: 3vw;
    font-weight: 500;
    letter-spacing: 0.01vw;
    color: rgb(110, 110, 110);
  }

  .MobileEmptyCart-txt-2::after {
    content: "";
    height: 0.3vh;
    width: 0%;
    background-color: rgb(110, 110, 110);
    display: block;
    transition: 0.5s ease-in-out;
  }

  .MobileEmptyCart-C-B2B1:hover .MobileEmptyCart-txt-2::after {
    content: "";
    height: 0.3vh;
    width: 100%;
    background-color: rgb(110, 110, 110);
    display: block;
  }

  /*MobileFulledCart*/

  .MobileFulledCart-background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileFulledCart-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
  }

  .MobileFulledCart-C-B1 {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 33%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .MobileFulledCart-C-B2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 40% 60%;
  }

  .MobileFulledCart-C-B2B1 {
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2vh 0 2vh;
  }

  .MobileFulledCart-txt-1 {
    margin: 0;
    font-size: 2.5vw;
    font-weight: 400;
    color: rgb(110, 110, 110);
    font-style: italic;
  }

  .MobileFulledCart-C-B2B2 {
    background-color: black;
    padding: 0 2vh 0 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .MobileFulledCart-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
  }

  /*MobileRenderOfCard*/

  .MobileRenderOfCard-background {
    border-bottom: 1px solid rgb(128, 128, 128, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileRenderOfCard-content {
    height: 80%;
    width: 90%;
    display: grid;
    grid-template-columns: 25% 60% auto;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 1vh;
  }

  .MobileRenderOfCard-img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .MobileRenderOfCard-B1 {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto 29%;
    align-content: space-between;
  }

  .MobileRenderOfCard-B1B1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: space-between;
  }

  .MobileRenderOfCard-txt-1 {
    margin: 0;
    font-size: 3vw;
    font-weight: 500;
    color: black;
  }

  .MobileRenderOfCard-txt-2 {
    margin: 0;
    font-size: 3vw;
    font-weight: 400;
    color: rgb(110, 110, 110);
    font-style: italic;
  }

  .MobileRenderOfCard-txt-3 {
    margin: 0;
    font-size: 3vw;
    font-weight: 400;
    color: rgb(110, 110, 110);
  }

  .MobileRenderOfCard-B1B2 {
    border: 1px solid rgb(128, 128, 128, 0.3);
    border-radius: 0.5vh;
    height: auto;
    width: 14vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100%;
    align-items: center;
    justify-content: space-around;
  }

  .MobileRenderOfCard-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: rgb(128, 128, 128, 1);
    font-size: 3vw;
    font-weight: 500;
  }

  .MobileRenderOfCard-txt-4 {
    margin: 0;
    text-align: center;
    color: rgb(128, 128, 128, 1);
    font-size: 3vw;
    font-weight: 500;
  }

  .MobileRenderOfCard-B2 {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .MobileRenderOfCard-svg {
    height: 2vh;
    width: auto;
    fill: rgb(128, 128, 128, 1);
  }
}
