/*Disable on desktop*/

@media (min-width: 1199.98px) {
  .MobileNavbar {
    display: none;
  }

  .MobileNavbar-notdisplayed {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .NoScroll {
    /* when modal active */
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
    scroll-behavior: none;
  }

  .MobileNavbar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileNavbar-notdisplayed {
    display: none;
  }

  .MobileNavbar-background {
    height: 12vh;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    transition: 0.2s;
    z-index: 2;
  }

  .MobileNavbar-background-scroll {
    background-color: rgb(245, 245, 245, 0.8);
    backdrop-filter: blur(10px);
  }

  .MobileNavbar-background-scroll:hover {
    background-color: rgb(245, 245, 245, 1);
    backdrop-filter: blur(10px);
  }

  .MobileNavbar-content {
    height: 100%;
    width: 95%;
    display: grid;
    grid-template-columns: 10% 70% 10%;
    grid-template-rows: 100%;
    justify-content: space-between;
  }

  .MobileNavbar-C-B1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileNavbar-svg-1 {
    height: 3.5vh;
    width: fit-content;
    fill: white;
  }

  .MobileNavbar-svg-1-scroll {
    fill: rgb(94, 94, 94);
  }

  .MobileNavbar-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .MobileNavbar-txt-1 {
    margin: 0;
    text-align: center;
    font-size: 5vw;
    font-weight: 600;
    color: white;
    line-height: 6vw;
  }

  .MobileNavbar-txt-1-scroll {
    color: rgb(94, 94, 94);
  }

  .MobileNavbar-C-B3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  /*RenderOfMobileFulledCartAnimation*/

  .RenderOfMobileFulledCartAnimation-background {
    background-color: white;
    height: 1vh;
    width: 1vh;
    border-radius: 50%;
    top: 3.7vh;
    position: absolute;
    animation: RenderOfMobileFulledCartAnimation-animation 0.6s ease-in-out
      infinite alternate;
  }

  .RenderOfMobileFulledCartAnimation-background-scroll {
    background-color: rgb(94, 94, 94);
  }

  @keyframes RenderOfMobileFulledCartAnimation-animation {
    0% {
      transform: translateY(-25%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
