.S3-background {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}

.S3-content {
  width: 98%;
  height: 100%;
  display: grid;
  grid-template-rows: 6% 94%;
  grid-template-columns: 100%;
}

.S3-C-B1 {
  background-color: none;
}

.S3-C-B2 {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-template-rows: 100%;
  grid-column-gap: 1%;
  justify-content: space-between;
}

.mySwiper-HP {
  display: none;
}

/*S3dbProducts*/

.S3dbProducts-background {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80% 20%;
  animation: 0.7s ease-in S3dbProducts-animation;
}

@keyframes S3dbProducts-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.S3dbProducts-background-notdisplayed {
  display: none;
}

.S3dbProducts-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S3dbProducts-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.S3dbProducts-txt-1 {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  padding: 1vh 2vh 1vh 2vh;
  color: rgb(28, 28, 28);
  font-size: 0.7vw;
  text-decoration: none;
  backdrop-filter: blur(5px);
}

.S3dbProducts-B2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.S3dbProducts-txt-2 {
  width: 80%;
  height: 50%;
  margin: 0;
  font-size: 0.6vw;
  line-height: 0.6vw;
}

/*S3dbProductsLoader*/

.S3dbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.S3dbProductsLoader-background-notdisplayed {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .S3-background {
    display: none;
  }
}