.DP-background {
  background-color: whitesmoke;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DP-content {
  height: 100%;
  width: 92%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6vh auto;
}

.DP-C-B1 {
  background-color: none;
}

.DP-C-B2 {
  display: grid;
  grid-template-columns: repeat(3, 50vh);
  grid-template-rows: repeat(5, 70vh);
  align-content: space-around;
  justify-content: space-around;
  grid-row-gap: 7vh;
  padding: 7vh 0 7vh 0;
}

/*DestacadosdbProducts*/

.DestacadosdbProducts-background {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60% 8%;
  align-content: space-around;
  animation: 0.7s ease-in S2dbProducts-animation;
  text-decoration: none;
}

.DestacadosdbProducts-background-notdisplayed {
  display: none;
}

.DestacadosdbProducts-B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DestacadosdbProducts-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.DestacadosdbProducts-B2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  align-items: center;
  opacity: 0;
  transform: translateY(2vh);
  transition: 0.3s;
}

.DestacadosdbProducts-txt-1 {
  margin: 0;
  text-align: center;
  margin: 0;
  font-size: 0.65vw;
  font-weight: 600;
  color: rgb(94, 94, 94);
}

.DestacadosdbProducts-txt-2 {
  margin: 0;
  text-align: center;
  font-size: 0.65vw;
  font-weight: 400;
  color: rgb(94, 94, 94);
}

.DestacadosdbProducts-background:hover .DestacadosdbProducts-B2 {
  opacity: 1;
  transform: translateY(0);
}

/*DestacadosdbProductsLoader*/

.DestacadosdbProductsLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.DestacadosdbProductsLoader-background-notdisplayed {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 75%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .DP-background {
    display: none;
  }
}
