.CEC-background-v1 {
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.CEC-content-v1 {
  height: 90%;
  width: 50%;
  background-color: whitesmoke;
  animation: 0.3s ease-out 0s 1 cartanimation2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
}

.CEC-background {
  background: linear-gradient(
    0deg,
    rgb(245, 245, 245) 50%,
    rgb(235, 235, 235) 50%
  );
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CEC-content {
  background-color: none;
  height: 100%;
  width: 30%;
  display: grid;
  grid-template-rows: 25% 50% 25%;
  grid-template-columns: 100%;
}

.CEC-C-B1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.CEC-txt-1 {
  text-align: center;
  margin: 0;
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0.01vw;
  color: rgb(110, 110, 110);
}

.CEC-C-B2 {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CEC-C-B2B1 {
  height: 60%;
  width: 100%;
  display: grid;
  grid-template-rows: 10% 80% 10%;
  grid-template-columns: 100%;
  cursor: pointer;
  text-decoration: none;
}

.CEC-C-B2B1B1 {
  background-color: none;
  height: 100%;
  width: 100%;
}

.CEC-C-B2B1B2 {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CEC-C-B2B1B2B1 {
  background-color: whitesmoke;
  height: 16vh;
  width: 16vh;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CEC-svg-1 {
  height: 23%;
  width: 23%;
  fill: rgb(110, 110, 110, 0.5);
}

.CEC-C-B2B1B3 {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CEC-txt-2 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  letter-spacing: 0.01vw;
  color: rgb(110, 110, 110);
}

.CEC-txt-2::after {
  content: "";
  height: 2px;
  width: 0%;
  background-color: rgb(110, 110, 110);
  display: block;
  transition: 0.5s ease-in-out;
}

.CEC-C-B2B1:hover .CEC-txt-2::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: rgb(110, 110, 110);
  display: block;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .CEC-background-v1 {
    display: none;
  }
}
