.CheckoutForm-background {
  height: 100%;
  width: 100%;
  display: flex;
}

.CheckoutForm-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 20% 70% 10%;
}

.CheckoutForm-C-B1 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: flex-start;
}

.CF-txt-1 {
  margin: 0;
  font-size: 1vw;
  font-weight: 400;
  color: rgb(64, 64, 64);
}

.CF-INPUT-background {
  height: 7vh;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70% 30%;
}

.CF-INPUT-B1 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CF-INPUT-inp {
  height: 70%;
  width: 100%;
  font-size: 0.8vw;
  font-weight: 500;
  color: black;
  background-color: white;
  border-radius: 0.5vh;
  border: 0.1vh solid rgb(128, 128, 128, 0.5);
  padding-left: 1vh;
  padding-top: 1vh;
}

.CF-INPUT-inp-v2 {
  height: 70%;
  width: 100%;
  font-size: 0.8vw;
  font-weight: 500;
  color: black;
  background-color: white;
  border-radius: 0.5vh;
  border: 0.1vh solid rgba(234, 47, 0, 0.823);
  padding-left: 1vh;
  padding-top: 1vh;
}

.CF-INPUT-label {
  left: 0;
  position: absolute;
  transition: 0.2s ease-in-out;
  transform-origin: 1vh 0;
  padding-left: 1vh;
  font-size: 0.8vw;
  font-weight: 400;
  color: rgb(64, 64, 64);
  pointer-events: none;
}

.CF-INPUT-inp:focus + .CF-INPUT-label,
.CF-INPUT-inp:not(:placeholder-shown) + .CF-INPUT-label {
  transform: translateY(-1vh) scale(0.8);
}

.CF-INPUT-inp-v2:focus + .CF-INPUT-label,
.CF-INPUT-inp-v2:not(:placeholder-shown) + .CF-INPUT-label {
  transform: translateY(-1vh) scale(0.8);
}

.CF-INPUT-inp-dropdown {
  height: 100%;
  width: 100%;
  border: 0.1vh solid rgb(128, 128, 128, 0.5);
  padding-left: 1vh;
  font-size: 0.8vw;
  font-weight: 400;
  color: rgb(64, 64, 64);
}

.CF-INPUT-B2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CF-INPUT-txt-1 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgba(234, 47, 0, 0.823);
  animation: 0.2s ease-in-out CF-INPUT-txt-1-animation;
}

@keyframes CF-INPUT-txt-1-animation {
  0% {
    transform: translateY(-1vh) scale(0.8);
  }
  100% {
    transform: translateY(0);
  }
}

.CheckoutForm-C-B2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto auto auto auto auto auto auto;
  grid-column-gap: 1vh;
  justify-content: space-between;
}

.CheckoutForm-C-B2B1 {
  grid-column: 1/7;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CheckoutForm-C-B2B2 {
  grid-column: 1/7;
  grid-row: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B3 {
  grid-column: 1/4;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B4 {
  grid-column: 4/7;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B5 {
  grid-column: 1/7;
  grid-row: 4/5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B6 {
  grid-column: 1/7;
  grid-row: 5/6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B7 {
  grid-column: 1/7;
  grid-row: 6/7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B8 {
  grid-column: 1/3;
  grid-row: 7/8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B9 {
  grid-column: 3/5;
  grid-row: 7/8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B10 {
  grid-column: 5/7;
  grid-row: 7/8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B2B11 {
  grid-column: 1/7;
  grid-row: 8/9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckoutForm-C-B3 {
  display: grid;
  grid-template-columns: min-content 50%;
  grid-template-rows: 100%;
  align-items: center;
  justify-content: space-between;
}

.CheckoutForm-C-B3B1 {
  height: fit-content;
  display: grid;
  grid-template-columns: min-content max-content;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 1vh;
  text-decoration: none;
}

.CheckoutForm-C-svg {
  height: 80%;
  width: fit-content;
  fill: rgb(64, 64, 64);
}

.CP-txt-5 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: rgb(64, 64, 64);
}

.CheckoutForm-C-B3B2 {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CheckoutForm-C-B3B2B1 {
  background-color: rgb(64, 64, 64);
  height: 80%;
  width: 60%;
  border-radius: 0.5vh;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border: none;
  text-decoration: none;
}

.CheckoutForm-C-B3B2B1:hover {
  background-color: rgb(94, 94, 94);
}

.CP-txt-6 {
  margin: 0;
  font-size: 0.7vw;
  font-weight: 500;
  color: whitesmoke;
  transition: 0.3s;
}

.CP-txt-6:hover {
  color: white;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .CheckoutForm-background {
    display: none;
  }
}
