@media (min-width: 1199.98px) {
  .PP-background {
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
  }

  .PP-content {
    background-color: none;
    height: 100%;
    width: 60%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6% 94%;
  }

  .PP-C-B1 {
    background-color: none;
  }

  .PP-C-B2 {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PostPayment-Loader {
    border: 0.3vh solid transparent;
    border-radius: 50%;
    border-top: 0.3vh solid rgb(128, 128, 128, 1);
    width: 2vh;
    height: 2vh;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/*Mobile*/

@media (max-width: 1199.98px) {
  .PP-background {
    height: fit-content;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    transition: 0.2s;
  }

  .PP-background-LoadingVersion {
    height: 100%;
  }

  .PP-content {
    height: auto;
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 12vh auto;
  }

  .PP-C-B1 {
    background-color: none;
  }

  .PP-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PostPayment-Loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(128, 128, 128, 1);
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
