.MobileAddToCartAnimation-background {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileAddToCartAnimation-Loader-background {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileAddToCartAnimation-Loader-content {
  border: 0.3vh solid transparent;
  border-radius: 50%;
  border-top: 0.3vh solid rgb(128, 128, 128, 1);
  width: 3vh;
  height: 3vh;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MobileAddToCartAnimation-svg {
  height: 3vh;
  width: auto;
  fill: rgb(128, 128, 128, 1);
}
