.MobileMenuEquipos-background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  animation: 0.2s ease MobileMenuEquipos-animation;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes MobileMenuEquipos-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.MobileMenuEquipos-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(9, 8vh);
}

.MobileMenuEquipos-C-B1 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: grid;
  grid-template-columns: 3vh auto 3vh;
  grid-template-rows: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0vh 3vh 0vh 3vh;
}

.MobileMenuEquipos-C-B1B1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileMenuEquipos-svg-1 {
  height: auto;
  width: 100%;
  fill: rgb(94, 94, 94);
}

.MobileMenuEquipos-txt-1 {
  margin: 0;
  font-size: 3.5vw;
  font-weight: 500;
  color: rgb(94, 94, 94);
  width: fit-content;
}

.MobileMenuEquipos-C-B2 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B3 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B4 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B5 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B6 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B7 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B8 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}

.MobileMenuEquipos-C-B9 {
  border-bottom: 0.1vh solid rgb(128, 128, 128, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vh 3vh 0vh 3vh;
  text-decoration: none;
}
