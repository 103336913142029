/*Disable on desktop*/

.MobileSectionOne-background {
  display: none;
}

@media (max-width: 1199.98px) {
  .MobileSectionOne-background {
    background-image: url(../../../../Images/image7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileSectionOne-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6% 88% 6%;
  }

  .MobileSectionOne-C-B1 {
    border: none;
  }

  .MobileSectionOne-C-B2 {
    border: none;
  }

  .MobileSectionOne-C-B3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MobileSectionOne-C-B3B1 {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: MobileSectionOne-C-B3B1-animation 2s infinite 0s;
    cursor: pointer;
  }

  @keyframes MobileSectionOne-C-B3B1-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    40% {
      transform: translateY(-30%);
    }
    60% {
      transform: translateY(-30%);
    }
  }

  .MobileSectionOne-svg {
    height: 5vh;
    width: auto;
    fill: white;
  }
}
