@media (min-width: 1199.98px) {
  .MobileCheckoutPage-background {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .MobileCheckoutPage-background {
    background-color: whitesmoke;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .MobileCheckoutPage-content {
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10vh min-content 8vh auto 10vh;
  }

  .MobileCheckoutPage-C-B1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2vh 0 2vh;
  }

  .MobileCheckoutPage-txt-1 {
    margin: 0;
    font-size: 6vw;
    font-weight: 600;
    color: rgb(94, 94, 94);
    text-decoration: none;
  }

  .MobileCheckoutPage-C-B3 {
    padding: 0 2vh 0 2vh;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 1vh;
  }

  .MobileCheckoutPage-txt-2 {
    margin: 0;
    font-size: 3.2vw;
    font-weight: 400;
    color: rgb(100, 100, 100);
  }

  .MobileCheckoutPage-txt-2-black {
    color: black;
    font-weight: 500;
  }

  .MobileCheckoutPage-svg-1 {
    height: 1.7vh;
    width: auto;
    fill: rgb(74, 74, 74);
  }

  .MobileCheckoutPage-C-B4 {
    border-top: 0.1vh solid rgb(128, 128, 128, 0.5);
    margin: 0 2vh 0 2vh;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    align-content: center;
    justify-content: flex-start;
    grid-column-gap: 3vh;
    grid-row-gap: 1vh;
  }

  .MobileCheckoutPage-txt-3 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-decoration: none;
  }

  .RenderOfMobileCheckEmptyCart-background {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobileCheckEmptyCart-content {
    height: 70vh;
    width: 90%;
    background: linear-gradient(
      0deg,
      rgb(245, 245, 245) 50%,
      rgb(235, 235, 235) 50%
    );
    animation: 0.3s ease-out 0s 1 RenderOfMobileCheckEmptyCart-animation;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 15vh auto;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
  }

  @keyframes RenderOfMobileCheckEmptyCart-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .RenderOfMobileCheckEmptyCart-C-B1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobileCheckEmptyCart-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(94, 94, 94);
  }

  .RenderOfMobileCheckEmptyCart-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobileCheckEmptyCart-C-B2B1 {
    height: 14vh;
    width: 14vh;
    background-color: whitesmoke;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .RenderOfMobileCheckEmptyCart-svg {
    height: 6vh;
    width: auto;
    fill: rgb(128, 128, 128);
  }

  .RenderOfMobileCheckEmptyCart-C-B3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RenderOfMobileCheckEmptyCart-txt-2 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(94, 94, 94);
    text-decoration: underline;
  }
}
