.productpage-background {
  background-color: none;
  height: 150%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 0.5fr;
  grid-template-columns: 100%;
  justify-content: center;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .productpage-background {
    display: none;
  }
}