/*Disable on desktop*/

.MobileSectionThree-background {
  display: none;
}

@media (max-width: 1199.98px) {
  .MobileSectionThree-background {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MobileSectionThree-content {
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-rows: 6% 94%;
    grid-template-columns: 100%;
  }

  .MobileSectionThree-C-B1 {
    border: none;
  }

  .MobileSectionThree-C-B2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
