/*Desktop*/

@media (min-width: 1199.98px) {
  .FAQSections-background {
    height: fit-content;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .FAQSections-content {
    height: 100%;
    width: 60%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 6vh auto;
  }

  .FAQSections-C-B1 {
    background-color: none;
  }

  .FAQSections-C-B2 {
    padding: 2vh 0 2vh 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: flex-start;
    justify-content: flex-start;
    grid-row-gap: 2vh;
  }

  .FAQSections-txt-1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.5vw;
    font-weight: 700;
    color: rgb(94, 94, 94);
  }

  .FAQSections-C-B2B1 {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: 3vh;
  }

  /*RenderOfFaqSection*/

  .RenderOfFaqSection-background {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: flex-start;
    grid-row-gap: 2vh;
  }

  .RenderOfFaqSection-txt-1 {
    margin: 0;
    font-size: 0.9vw;
    font-weight: 600;
    color: rgb(94, 94, 94);
    text-align: justify;
    text-transform: uppercase;
  }

  .RenderOfFaqSection-txt-2 {
    margin: 0;
    font-size: 0.7vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: justify;
    white-space: pre-wrap;
  }
}

/*Mobile*/

@media (max-width: 1199.98px) {
  .FAQSections-background {
    height: fit-content;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .FAQSections-content {
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 12vh auto;
  }

  .FAQSections-C-B1 {
    background-color: none;
  }

  .FAQSections-C-B2 {
    padding: 2vh 0 2vh 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: flex-start;
    justify-content: flex-start;
    grid-row-gap: 2vh;
  }

  .FAQSections-txt-1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 6vw;
    font-weight: 700;
    color: rgb(94, 94, 94);
  }

  .FAQSections-C-B2B1 {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: 3vh;
  }

  /*RenderOfFaqSection*/

  .RenderOfFaqSection-background {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: flex-start;
    grid-row-gap: 2vh;
  }

  .RenderOfFaqSection-txt-1 {
    margin: 0;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgb(94, 94, 94);
    text-align: left;
    text-transform: uppercase;
  }

  .RenderOfFaqSection-txt-2 {
    margin: 0;
    font-size: 3vw;
    font-weight: 400;
    color: rgb(94, 94, 94);
    text-align: left;
    white-space: pre-wrap;
  }
}
