.SOS-background {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.SOS-content {
  height: 96%;
  width: 75%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 91% 8%;
  align-content: space-between;
  justify-content: center;
}

.SOS-C-B1 {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 0.7s ease-in SOS-C-B1-animation;
}

@keyframes SOS-C-B1-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SOS-C-B1-notdisplayed {
  display: none;
}

.mySwiper2 {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.SS-background {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SS-img-1 {
  height: fit-content;
  width: 100%;
}

.SS-img-1-v2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.SOS-C-B2 {
  background-color: none;
  display: grid;
  grid-template-columns: 13% 74% 13%;
  grid-template-rows: 100%;
  animation: 0.7s ease-in SOS-C-B1-animation;
}

.SOS-C-B2-notdisplayed {
  display: none;
}

.SOS-C-B2B1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.SOS-svg-1 {
  height: 30%;
  width: fit-content;
  fill: rgb(40, 40, 40);
}

.SOS-C-B2B2 {
  border: none;
  display: flex;
}

.SOS-C-B2B3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.SOS-svg-2 {
  height: 30%;
  width: fit-content;
  fill: rgb(40, 40, 40);
}

.mySwiper {
  height: 100%;
  width: 100%;
}

.SS-img-2 {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/*Loaders*/

.SOSdbImageLoader-background {
  animation: skeleton-loading 1s linear infinite alternate;
}

.SOSdbImageLoader-background-notdisplayed {
  display: none;
}

/*Disable on mobile*/

@media (max-width: 1199.98px) {
  .SG-background {
    display: none;
  }
}